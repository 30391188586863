.footer {
    display: block;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #e1e1e1;
    overflow: hidden;
}
.footer .container {
    width: 100%;
    text-align: center;
}
.footer .container p {
    font-size: 14px;
    color: #999;
    line-height: 50px;
}