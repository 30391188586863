.header {
    display: block;
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
    padding: 10px 20px;
    overflow: hidden;
}
.header .container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.header .nav {
    display: flex;
}
.header .wrapper {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    white-space: nowrap;
}
.header .nav .wrapper {
    margin-right: 5px;
}
.logo {
    display: inline-block;
    color: #393939;
    font-style: normal;
    font-weight: normal;
    cursor: pointer;
    text-decoration: none;
    height: 50px;
    line-height: 50px;
}
.logo_wrapper .logo img {
    vertical-align: top;
    height: 100%;
}
.logo_wrapper .logo i {
    display: inline-block;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    padding: 0 10px;
    text-transform: uppercase;
    line-height: 50px;
    color: #42B3E5;
}
.map_wrapper, .mail_wrapper {
    display: block;
    height: 30px;
    line-height: 30px;
    margin: 10px 5px;
    text-decoration: none;
    justify-content: center;
}
.map_wrapper img, .mail_wrapper img {
    height: 100%;
    vertical-align: top;
}
.map_wrapper i, .mail_wrapper i {
    display: inline-block;
    font-style: normal;
    line-height: 30px;
    font-size: 13px;
    text-align: center;
    padding: 0 5px;
    color: #5c727d;
}