.layout {
    width: 100%;
    padding: 20px;
    background-color: #F8F8F8;
}
.layout .container {
    display: block;
}
.product-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.product-item {
    margin: 20px;
    width: 340px;
    height: 460px;
    background-color: #fff;
}
.product-link {
    display: block;
    padding: 20px;
    text-decoration: none;
}
.product-img {
    width: 300px;
    height: 300px;
}
.product-img img {
    height: 100%;
    width: 100%;
}
.product-title {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #333;
}
.product-price {
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #fd384f;
}